//colors

$bannerColor: #1a1a27;

//Colors
$sidebarBackground: #222d43;
$heading_color: #004a74;
$primary-color: #009ef7;
$grayfaded-color: #b1b1b1;
$txthalfgrayblack-color: #263238bf;

//font family
$font-montserrat: 'Montserrat';
$font-poppins: 'Poppins';
$font-roboto: 'roboto';

//fontSize
$font-96: 96px;
$font-75: 75px;
$font-45: 45px;
$font-40: 40px;
$font-34: 34px;
$font-30: 30px;
$font-18: 18px;
$font-25: 25px;
$font-20: 20px;
$font-16: 16px;
$font-15: 15px;
$font-14: 14px;
$font-13: 13px;
$font-12: 12px;
$font-10: 10px;

//width
$width-150: 150px;

//fontWeight
$font-500: 500;
$font-600: 600;
$font-450: 450;
$font-400: 400;

//style font
$font-normal: normal;

//line height
$line-height-41: 41px;
$line-height-30: 30px;
$line-height-54: 54px;
$line-height-25: 25px;
$line-height-24: 24px;
$line-height-20: 20px;

// common mixin
@mixin fontStyleSizeHeight($weight, $size, $height) {
    font-family: $font-montserrat;
    font-style: $font-normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $height;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

// business partner Admin Mixins

@mixin modalsDiscardCancelBtn {
    border: 1px solid #222d43;
    border-radius: 5px;
    width: 100%;
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-14, $height: $line-height-20);
    text-align: center;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #222d43;
}

@mixin detailsBtn {
    @include fontStyleSizeHeight($weight: $font-400, $size: $font-14, $height: 22px);
    font-family: 'Roboto';
    text-align: center;
    color: #888888;
    border: 1px solid #888888;
    border-radius: 2px;
}

@mixin modalSubmitSaveBtn {
    background: #222d43;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 5px;
    width: 100%;
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-14, $height: $line-height-20);
    text-align: center;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #ffffff;
}

@mixin createBtn {
    @include fontStyleSizeHeight($weight: 600, $size: 14px, $height: 20px);
    width: 239px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #ffffff;
}

@mixin deleteBtn {
    @include fontStyleSizeHeight($weight: 600, $size: 14px, $height: 20px);
    text-align: center;
    letter-spacing: 0.1em;
    color: #f7991a;
    border: 1px solid #f7991a;
    border-radius: 2px;
}

@mixin editBtn {
    @include fontStyleSizeHeight($weight: 600, $size: 14px, $height: 20px);
    text-align: center;
    letter-spacing: 0.1em;
    color: #222d43;
    border: 1px solid #222d43;
    border-radius: 2px;
}

@mixin activeInactive($color) {
    font-family: $font-montserrat;
    font-style: $font-normal;
    font-weight: $font-13;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $color;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin createQueOrange {
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-16, $height: $line-height-25);
    letter-spacing: 0.1em;
    color: #f7991a;
}

@mixin button_x {
    width: 100%;
    max-width: 260px;
    background: #ffffff;
    border: 1px solid #f7991a;
    border-radius: 5px;
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-14, $height: $line-height-20);
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
}

@mixin addParamBtn {
    width: 211px;
    background: #f7991a;
    border: none;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 5px;
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-14, $height: $line-height-20);
    text-align: center;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #ffffff;
}

@mixin paramBtnDelete {
    width: 30px;
    height: 30px;
    background: #f7991a;
    border-radius: 50%;
    padding: 0;
    color: #ffffff;
    margin-left: 10px;
}

@mixin notAddDivOrParamAddDivH4 {
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-14, $height: $line-height-20);
    margin-bottom: 0;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
    margin-left: 10px;
}

@mixin configAddStyle($border-color) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    background: #ffffff;
    border: 0.5px solid $border-color;
    border-radius: 2px;
    padding: 15px 15px;
    height: auto;
}

@mixin addNotificationBtn {
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-14, $height: $line-height-20);
    width: 223px;
    background: #ffdd34;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 5px;
    border: none;
    text-align: center;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #222d43;
}

@mixin height50DiscardBtn {
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-16, $height: $line-height-20);
    width: 326px;
    height: 50px;
    border: none;
    border: 1px solid #888888;
    border-radius: 10px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #888888;
}

@mixin height50SubmitBtn {
    @include fontStyleSizeHeight($weight: $font-600, $size: $font-16, $height: $line-height-20);
    max-width: 100%;
    width: 326px;
    height: 50px;
    border: none;
    background: #222d43;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 10px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
}

@import '../../../styles/variables.scss';

.mainStyle {
    margin: 40px;

    .heading {
        margin-bottom: 30px;

        h3 {
            @include createQueOrange;
            font-size: 20px;
            letter-spacing: normal;
            line-height: 24px;
        }

        h4 {
            @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 25px);
            letter-spacing: 0.1em;
            color: #5e5e5e;
        }
    }

    .logoComp {
        margin-bottom: 30px;

        .logoImg {
            img {
                border-radius: 50%;
            }
        }

        // .uploadLogo {
        //     .button {
        //         @include fontStyleSizeHeight($weight: 600, $size: 14px, $height: 20px);
        //         text-align: center;
        //         letter-spacing: 0.1em;
        //         color: #FFFFFF;
        //         background: #222D43;
        //         box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        //         border-radius: 5px;
        //         width: 162px;
        //     }
        // }
    }

    .uploadText {
        h3 {
            @include fontStyleSizeHeight($weight: 500, $size: 16px, $height: 20px);
            color: #222d43;
        }
    }

    .uploadDiv {
        border: none;
        margin-bottom: 30px;

        .pictureIcon {
            font-size: 35px;
            color: #222d43;
        }

        img {
            object-fit: cover;
        }

        .ant-btn {
            padding: 0px;
        }
    }

    .buttons {
        margin: 20px 0;
        .cancelBtn {
            @include height50DiscardBtn;
        }

        .saveBtn {
            @include height50SubmitBtn;
        }
    }

    @media (min-width: 768px) and (max-width: 992px) {
        margin: 45px 20px;
    }

    @media (min-width: 500px) and (max-width: 768px) {
        margin: 45px 15px;
    }

    @media (min-width: 250px) and (max-width: 500px) {
        margin: 45px 8px;

        .logoComp {
            .logoImg {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .uploadLogo {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .buttons {
            margin-bottom: 30px;

            .cancelBtn {
                width: 100%;
            }

            .saveBtn {
                width: 100%;
            }
        }
    }

    // subscription style
}

.subscriptionHeading {
    margin-bottom: 50px;
    margin-top: 50px;

    h1 {
        text-align: center;
        @include fontStyleSizeHeight($weight: 600, $size: 34px, $height: 41px);
        color: #f7991a;

        .span {
            color: #222d43;
        }
    }

    p {
        text-align: center;
        @include fontStyleSizeHeight($weight: 600, $size: 20px, $height: 24px);
        color: #5e5e5e;
    }

    @media (min-width: 250px) and (max-width: 500px) {
        h1 {
            @include fontStyleSizeHeight($weight: 600, $size: 24px, $height: 31px);
        }

        p {
            @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 20px);
        }
    }
}

.cardStyle {
    max-width: 340px;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 3px 25px rgba(59, 74, 116, 0.15);
    border-radius: 20px;
    padding: 20px;

    .headPart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 5px;

        h3 {
            @include fontStyleSizeHeight($weight: 600, $size: 24px, $height: 29px);
            color: #f7991a;
            margin-bottom: 0;
        }

        .popularBtn {
            @include fontStyleSizeHeight($weight: 700, $size: 10px, $height: 12px);
            text-align: center;
            letter-spacing: 0.19em;
            text-transform: uppercase;
            background: #222d43;
            color: #ffdd34;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 205px;
            width: 124px;
            height: 32px;
            border: none;
            cursor: default;
        }
    }

    .dollarOrMonth {
        margin-bottom: 35px;

        h1 {
            @include fontStyleSizeHeight($weight: 600, $size: 34px, $height: 41px);
            color: #222d43;
        }
    }

    .paragraphDivMain {
        margin-bottom: 35px;

        .paragraphDiv {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;

            .icon {
                color: #222d43;
                font-size: 18px;
            }

            p {
                margin-bottom: 0;
                @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 25px);
                letter-spacing: 0.1em;
                color: #888888;
            }
        }
    }

    .buttonDiv {
        margin-bottom: 10px;

        button {
            @include height50SubmitBtn;
            width: 100%;
        }
    }
}
.isPlatinium {
    transition: 0.3s all ease-in-out;
    background: #222d43;
    box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.08);
    border-radius: 20px;

    .headPart {
        h3 {
            color: #ffffff;
        }

        .popularBtn {
            color: #222d43;
            background: #ffdd34;
        }
    }

    .dollarOrMonth {
        h1 {
            color: #ffffff;
        }
    }

    .paragraphDivMain {
        .paragraphDiv {
            .icon {
                color: #ffffff;
            }

            p {
                color: #e0e0e0;
            }
        }
    }

    .buttonDiv {
        button {
            background: #f7991a;
        }
    }
}

.paymentModal {
    .headerPart {
        h3 {
            letter-spacing: 0.1em;
            @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 25px);
            color: #222d43;
            margin-bottom: 15px;
        }
        p {
            letter-spacing: 0.1em;
            @include fontStyleSizeHeight($weight: 500, $size: 14px, $height: 22px);
            color: #222d43;
        }
    }
    .payBtnPart {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 70px;
        margin-bottom: 15px;
        button {
            border: none !important;
            #payment-btn {
                background: #f7991a;
                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                border-radius: 5px;
                border: none !important;
                width: 196px !important;
                height: 40px !important;
                @include fontStyleSizeHeight($weight: 600, $size: 14px, $height: 20px);
                text-align: center;
                color: #ffffff;
                letter-spacing: 0.1em;
            }
        }
    }
}

.mainPayment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;

    .imgContainer {
        img {
            width: 20em;
            height: auto;
        }
    }
    .bottomContainer {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .success {
            text-align: center;
            color: #1ecd8c;
            @include fontStyleSizeHeight($weight: 600, $size: 34px, $height: 41px);
        }
        .failed {
            text-align: center;
            color: #fe302c;
            @include fontStyleSizeHeight($weight: 600, $size: 34px, $height: 41px);
        }
        .cancel {
            text-align: center;
            color: #f7991a;
            @include fontStyleSizeHeight($weight: 600, $size: 34px, $height: 41px);
        }
        h5 {
            text-align: center;
            letter-spacing: 0.1em;
            font-feature-settings: 'tnum' on, 'lnum' on;
            @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 25px);
            color: #222d43;
            max-width: 80%;
            margin: 0 auto;
        }
        .cancelBTN {
            margin-top: 45px;
            text-align: center;
            letter-spacing: 0.1em;
            font-feature-settings: 'tnum' on, 'lnum' on;
            @include fontStyleSizeHeight($weight: 600, $size: 14px, $height: 20px);
            width: 185px;
            height: auto;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 5px;
        }
        .retry {
            margin-top: 45px;
            text-align: center;
            letter-spacing: 0.1em;
            font-feature-settings: 'tnum' on, 'lnum' on;
            @include fontStyleSizeHeight($weight: 600, $size: 14px, $height: 20px);
            color: #ffffff;
            width: 185px;
            height: auto;
            background: #222d43;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 5px;
        }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        .bottomContainer {
            h5 {
                width: 80%;
            }
        }
    }
    @media (min-width: 500px) and (max-width: 768px) {
        margin: 0 40px;
        .bottomContainer {
            .success {
                @include fontStyleSizeHeight($weight: 600, $size: 32px, $height: 40px);
            }
            .failed {
                @include fontStyleSizeHeight($weight: 600, $size: 32px, $height: 40px);
            }
            .cancel {
                @include fontStyleSizeHeight($weight: 600, $size: 32px, $height: 40px);
            }
            h5 {
                max-width: 100%;
            }
        }
    }
    @media (min-width: 250px) and (max-width: 500px) {
        margin: 0 20px;
        .bottomContainer {
            .success {
                @include fontStyleSizeHeight($weight: 600, $size: 30px, $height: 38px);
            }
            .failed {
                @include fontStyleSizeHeight($weight: 600, $size: 30px, $height: 38px);
            }
            .cancel {
                @include fontStyleSizeHeight($weight: 600, $size: 30px, $height: 38px);
            }
            h5 {
                max-width: 100%;
            }
        }
    }
}

@import '../../../styles/variables.scss';

.MainStyle {
    .bannerMain {
        background: white;
        box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.08);
        min-height: 100vh;
        position: relative;

        .banner_top {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 50%;
            .banner_top_img {
                width: 100%;
            }
        }
        .banner_bottom {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            max-width: 50%;

            .banner_bottom_img {
                width: 100%;
            }
        }

        .banner {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            min-height: 100%;
            position: relative;

            .newStyle {
                display: flex;
                justify-content: space-around;
                align-items: center;
                gap: 50px;
                margin-bottom: 50px;
                :nth-child(1) {
                    position: relative;
                    .tagline {
                        position: absolute;
                        bottom: 0;
                        right: -155px;
                    }
                }
            }
            .banner_logo {
                max-width: 200px;
            }
            .heading {
                margin-top: 10px;
                @include fontStyleSizeHeight($weight: 700, $size: 48px, $height: 59px);
                color: #222d43;
                margin-bottom: 10px;
            }
            .tagline {
                @include fontStyleSizeHeight($weight: 700, $size: 13px, $height: 16px);
                color: #222d43;
                letter-spacing: 0.2em;
            }
            .banner_img {
                max-width: 300px;
                padding: 48px 0;
                img {
                    width: 100%;
                }
            }
            .sec_heading {
                @include fontStyleSizeHeight($weight: 600, $size: 34px, $height: 41px);
                color: #222d43;
                text-align: center;
            }
            .scrollTo {
                font-size: 30px;
                font-weight: 600;
                color: #222d43;
                background: none;
                position: absolute;
                bottom: 5%;
                z-index: 1;

                .btn {
                    padding: 5px 5px 0px 5px;
                    border-radius: 50%;
                    transition: 0.1s all ease;
                }

                &:hover {
                    color: #f7991a;

                    .btn {
                        border: 2px solid #f7991a;
                    }
                }
            }
            @media (min-width: 1200px) and (max-width: 1440px) and (max-height: 768px) {
                .banner_img {
                    max-width: 180px;
                }
                .banner_logo {
                    max-width: 160px;
                }
                .heading {
                    @include fontStyleSizeHeight($weight: 700, $size: 38px, $height: 49px);
                }
                .tagline {
                    @include fontStyleSizeHeight($weight: 700, $size: 12px, $height: 15px);
                }
                .sec_heading {
                    @include fontStyleSizeHeight($weight: 600, $size: 24px, $height: 31px);
                }
            }
            @media (min-width: 1200px) and (max-width: 1440px) and (min-height: 769px) {
                .banner_img {
                    max-width: 250px;
                }
            }
            @media (min-width: 992px) and (max-width: 1200px) {
                .banner_img {
                    max-width: 220px;
                }
            }

            @media (min-width: 280px) and (max-width: 500px) {
                .newStyle {
                    gap: 20px;
                    :nth-child(1) {
                        .tagline {
                            bottom: -3px;
                            right: -170px;
                        }
                    }
                }
                .banner_logo {
                    max-width: 165px;
                }
                .heading {
                    font-size: 38px;
                    line-height: 49px;
                }
                .sec_heading {
                    font-size: 28px;
                    line-height: 39px;
                }
                .banner_img {
                    max-width: 180px;
                }
            }
        }
    }

    .mainForm {
        min-height: 100vh;
        background: #fbfbfc;

        .formContent {
            display: flex;
            justify-content: center;
            flex-direction: column;
            min-height: 100%;
            width: 100%;
            max-width: 500px;
            margin: 0 auto;

            .headings {
                padding: 20px 0;

                .welcome {
                    @include fontStyleSizeHeight($weight: 600, $size: 10px, $height: 12px);
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: #888888;
                    margin-bottom: 20px;
                }

                .signIn {
                    @include fontStyleSizeHeight($weight: 600, $size: 34px, $height: 41px);
                    color: #222d43;
                }

                .pleaseEnterPass {
                    @include fontStyleSizeHeight($weight: 600, $size: 26px, $height: 31px);
                    color: #222d43;
                }

                .forgotCaption {
                    @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 26px);
                    color: #222d43;
                }
            }

            .ForgetLink {
                margin-top: 30px;
                @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 20px);
                text-decoration: underline;
                color: #f7991a;
            }

            .SignUpLink {
                text-align: center;
                letter-spacing: 0.05em;
                @include fontStyleSizeHeight($weight: 600, $size: 16px, $height: 20px);
                color: #222d43;
                margin-top: 30px;
                max-width: 490px;

                a {
                    color: #1ecd8c;
                }

                @media (max-width: 992px) {
                    max-width: 100%;
                }
            }

            .button {
                margin-top: 45px;

                .btn {
                    min-width: 100%;
                    width: 490px;
                    height: 55px;
                    background: #222d43;
                    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                    border-radius: 10px;
                    @include fontStyleSizeHeight($weight: 600, $size: 18px, $height: 22px);
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-transform: capitalize;
                    color: #ffffff;
                }
            }

            @media (min-width: 992px) and (max-width: 1200px) {
                max-width: 400px;
                margin: 0 auto;

                .button {
                    .btn {
                        width: 100%;
                    }
                }
            }

            @media (min-width: 500px) and (max-width: 768px) {
                max-width: 100%;
                padding: 0 50px;

                .button {
                    .btn {
                        min-width: 100%;
                        width: 100%;
                    }
                }
            }

            @media (min-width: 280px) and (max-width: 500px) {
                max-width: 100%;
                padding: 0 15px;

                .button {
                    .btn {
                        min-width: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@import '~antd/dist/antd.less';

.collapsed {
    display: none;
    transition: all 1s linear !important;
    font-size: 18px;
    margin-bottom: 0;
}
.disabled-sidbar-queue{
    color: gray !important;
    font-weight: 700;
}
.centerSpinnerForLazyComponents {
    position: fixed;
    top: 45%;
    left: 50%;
    z-index: 1;
}

.col-xs-offset-1 {
    font-size: 50px !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-size: 14px !important;
    // line-height: 22px !important;
}

.ant-input-search-button {
    height: 44px;
    border: none;
    border-radius: 0px;
    background: #222D43;
    color: #fff!important;
}
.ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']
    .ant-steps-item-title {
    font-size: 14px !important;
    // line-height: 22px !important;
}
.ant-table-container table > thead > tr th {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #222D43;
    text-transform: capitalize;
}
.ant-table-container table > tbody > tr td{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.85);
   
}
@media (min-width: 280px) and (max-width: 500px) {
    .centerSpinnerForLazyComponents {
        left: 42%;
    }
    .ant-alert-action {
        margin-left: 0px !important;
    }
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after{
    box-shadow: inset -1.5px 0 0px 0px rgba(0, 0, 0, 0.55) !important;
}

@hack: true; @import "/home/omkar/Projects/reactJs/q-please/ant-design-less-files/theme.less";
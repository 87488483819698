@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    font-family: 'Montserrat', 'Poppins';
}
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box !important;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}
.ant-table-cell-fix-right {
    z-index: 0 !important;
}
.centerSpinnerForLazyComponents img {
    animation: rotation 0.9s infinite linear;
}
.mainLogo{
    width: 30px;
    margin-left: -8px !important;
    height: auto;

}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    color: rgb(0 0 0);
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
    color: #b1b1b1 !important;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 6px solid #f7991a !important;
}
/* ant tab  style*/
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
}
.ant-tabs-ink-bar {
    height: 4px !important;
    border-radius: 3px;
    max-width: 60px;
    margin: 0 auto;
}
/* .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
} */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    padding: 11px 0px !important;
}
.ant-tabs-tab {
    padding: 0 !important;
    font-size: 16px !important;
}
.ant-tabs-tab-btn {
    transition: all 0.3s;
    font-weight: 600;
    padding: 11px 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-left: 45px;
    margin-right: 45px;
}

@media (min-width: 250px) and (max-width: 768px) {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
        margin-left: 35px;
        margin-right: 0px;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
        margin-left: 0 !important;
    }
}
/* ant tab end style*/

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: #222d43 !important;
    background-color: #ffcfc3 !important;
}
.ant-menu-item-selected a {
    color: #222d43 !important;
}
.ant-collapse {
    border: none !important;
}
.ant-collapse > .ant-collapse-item {
    border-bottom: none !important;
    background: white;
    border-bottom: 20px solid #f5f8fa !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #004a74 !important;
    padding: 20px 20px !important;
    border-bottom: 1px solid transparent !important;
}

.collapse-container-custom > .ant-collapse-item {
    border-bottom: none !important;
}

.ant-collapse-content {
    border-top: none !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    background-color: #009ef7;
    border-radius: 50%;
    padding: 5px;
    color: rgb(255 255 255);
    width: 22px;
    height: 22px;
    font-weight: 700 !important;
}

.site-layout .site-layout-background {
    background: #ffffff !important;
}
::-webkit-scrollbar {
    width: 5px;
    height: auto;
}
/* @media (max-width: 1440px) { */
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
         height: 5px;
        /* height: auto; */
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(245, 245, 245);
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #FFCFC3 ;
        border-radius: 10px;
        transition: 3s !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background:  #f5ac9a;
    }
/* } */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.ant-input {
    font-size: 14px !important;
    padding: 10px !important;
    height: 45px;
}
.ant-input-affix-wrapper {
    padding: 0px 5px 0 0 !important;
}

.ant-table-container table > thead {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #222d43;
}

/* radio antd */

.newRadio,
.modalRadio .ant-radio-wrapper span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #888888;
}
.newRadio,
.modalRadio .ant-radio-wrapper-checked span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
}

@media (max-width: 768px) {
    .ant-layout-sider-collapsed {
        width: 80% !important;
    }

    .ant-layout-sider-children .ant-menu-inline-collapsed {
        width: 0% !important;
        overflow: auto;
    }

    .ant-layout-sider {
        width: 520px;
        background: none !important;
    }
    .ant-table-content::-webkit-scrollbar {
        width: auto;
        height: 4px;
    }

    /* Track */
    .ant-table-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgb(208, 248, 246);
        border-radius: 10px;
    }

    /* Handle */
    .ant-table-content::-webkit-scrollbar-thumb {
        background: #FFCFC3;
        border-radius: 10px;
    }

    /* Handle on hover */
    .ant-table-content::-webkit-scrollbar-thumb:hover {
        background:  #f5ac9a;
    }
}
/* .my-class-payment{
    border: none !important;
} */
/*  antDesign add filled*/
.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0px;
    color: #ffffff !important;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #f7991a;
    padding: 5px;
    border-radius: 50%;
}
.dynamic-delete-button:hover {
    color: #f7991a !important;
    border: 1px solid #f7991a !important;
    background-color: #ffffff;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
.uploadDiv .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px !important;
    border: none !important;
    border-radius: 10px;
}
.ant-upload.ant-upload-select-picture-card {
    border: none !important;
    background: #fafafa !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.list .ant-list-item-meta {
    margin-bottom: 0px !important;
}
.list .ant-list-item {
    padding: 16px 0px !important;
}
.noData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
}
.table-row-light{
    background: #FFFFFF;
}
.table-row-dark{
    background: #FFF9F7;
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #a4a4a4 !important;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #a4a4a4 !important;
}